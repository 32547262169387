<template>
	<div class="wrap wrap--sub">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
            <div class="collection-index-container">
                <div class="page-head">
                    <div class="page-head__inner">
                        <h1 class="page-head__title">DIY</h1>
                        <!-- <ul class="breadcrumb">
                            <li class="breadcrumb__list">Home</li>
                            <li class="breadcrumb__list">컬렉션</li>
                            <li class="breadcrumb__list">국내</li>
                            <li class="breadcrumb__list">디앤디 컬렉션 인덱스</li>
                        </ul> -->
                    </div>
                </div>
                
                <ul class="tab-list tab-style">
                    <li class="on"><button type="button" class="button">매직스티커벽지</button></li>
                    <li><a href="/diy/glue" class="button">매직풀나오는 벽지</a></li>
                </ul>

                <ul class="tab-contents">
                    <li class="on">
                        <!-- S: 매직스티커벽지 -->
                        <div>
                            <div class="sticker-section">
                                <div class="container">
                                    <div class="align-center">
                                        <img src="/images/sub/diy/sticker-img.png" alt="도배, 고민되셨나요? 이제 고민하지 마세요! 매직스티커벽지로 간편하게 셀프도배를 완성하세요.">
                                    </div>
                                </div>
                            </div>
                            <div class="sticker-section2">
                                <div class="container">
                                    <h2 class="page-title50 align-center">디아이디 매직스티커벽지 <br class="pc"/><strong>4개 강점 요소</strong></h2>
                                    <div class="sticker-list">
                                        <ol>
                                            <li>
                                                <h3 class="sl-txt">
                                                    <span class="sl-num">1</span>
                                                    기포가 생겨도 여러 번 떼였다 붙이가 가능해서 <br class="pc"/>쉽게 제거할 수 있습니다.
                                                </h3>
                                                <div class="sl-img">
                                                    <img src="/images/sub/diy/sticker-img2.png" alt="이미지">
                                                </div>
                                            </li>
                                            <li>
                                                <h3 class="sl-txt">
                                                    <span class="sl-num">2</span>
                                                    Extra-washable 제품으로 표면이 오염되었을 경우 <br class="pc"/>물걸레등으로 쉽게 제가 가능합니다.
                                                </h3>
                                                <div class="sl-img">
                                                    <img src="/images/sub/diy/sticker-img3.png" alt="이미지">
                                                </div>
                                            </li>
                                            <li>
                                                <h3 class="sl-txt">
                                                    <span class="sl-num">3</span>
                                                    시공 후 떼야할 경우 전혀 본드 자국 같은 이물질이 <br class="pc"/>남지 않습니다.
                                                </h3>
                                                <div class="sl-img">
                                                    <img src="/images/sub/diy/sticker-img4.png" alt="이미지">
                                                </div>
                                            </li>
                                            <li>
                                                <h3 class="sl-txt">
                                                    <span class="sl-num">4</span>
                                                    매직스티커 벽지는 실크벽지.합지벽지 등 어떤 기존 <br class="pc"/>벽지위에도 완벽하게 부착이 가능합니다.
                                                </h3>
                                                <div class="sl-img">
                                                    <img src="/images/sub/diy/sticker-img5.png" alt="이미지">
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                    <div class="align-center">
                                        <a role="link" href="https://smartstore.naver.com/didstore" target="_blank" class="button button--round button--point button--xl">쇼핑몰 바로가기</a>
                                    </div>
                                </div>
                            </div>
                            <div class="sticker-section3">
                                <div class="container">
                                    <h2 class="page-title55 align-center">질감이 다른 단색부터 수준 높은 <br class="pc"/><strong>패턴 디자인까지 한 눈에!</strong></h2>
                                    <div class="sticker-design-list">
                                        <ul>
                                            <li>
                                                <div class="sdl-img"><img src="/images/sub/diy/sticker-img6.png" alt="이미지"></div>
                                                <h2 class="sdl-tit">화이트</h2>
                                            </li>
                                            <li>
                                                <div class="sdl-img"><img src="/images/sub/diy/sticker-img7.png" alt="이미지"></div>
                                                <h2 class="sdl-tit">그레이</h2>
                                            </li>
                                            <li>
                                                <div class="sdl-img"><img src="/images/sub/diy/sticker-img8.png" alt="이미지"></div>
                                                <h2 class="sdl-tit">매트릭브릭</h2>
                                            </li>
                                            <li>
                                                <div class="sdl-img"><img src="/images/sub/diy/sticker-img9.png" alt="이미지"></div>
                                                <h2 class="sdl-tit">라티스그레이</h2>
                                            </li>
                                            <li>
                                                <div class="sdl-img"><img src="/images/sub/diy/sticker-img10.png" alt="이미지"></div>
                                                <h2 class="sdl-tit">엔틱트리</h2>
                                            </li>
                                            <li>
                                                <div class="sdl-img"><img src="/images/sub/diy/sticker-img11.png" alt="이미지"></div>
                                                <h2 class="sdl-tit">블루타일</h2>
                                            </li>
                                            <li>
                                                <div class="sdl-img"><img src="/images/sub/diy/sticker-img12.png" alt="이미지"></div>
                                                <h2 class="sdl-tit">플라워가든</h2>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- E: 매직스티커벽지 -->
                    </li>
                    <li>
                    </li>
                </ul>
            </div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import MainHeader from '@/components/client/inc/main-header.vue';
import MainFooter from '@/components/client/inc/main-footer.vue';

export default{
	components: {
		MainHeader,
		MainFooter
	},
	created() {
	},
	mounted() {
		this.init();
	},
		}
</script>
