var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--sub"
  }, [_c('main-header'), _vm._m(0), _c('main-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "collection-index-container"
  }, [_c('div', {
    staticClass: "page-head"
  }, [_c('div', {
    staticClass: "page-head__inner"
  }, [_c('h1', {
    staticClass: "page-head__title"
  }, [_vm._v("DIY")])])]), _c('ul', {
    staticClass: "tab-list tab-style"
  }, [_c('li', {
    staticClass: "on"
  }, [_c('button', {
    staticClass: "button",
    attrs: {
      "type": "button"
    }
  }, [_vm._v("매직스티커벽지")])]), _c('li', [_c('a', {
    staticClass: "button",
    attrs: {
      "href": "/diy/glue"
    }
  }, [_vm._v("매직풀나오는 벽지")])])]), _c('ul', {
    staticClass: "tab-contents"
  }, [_c('li', {
    staticClass: "on"
  }, [_c('div', [_c('div', {
    staticClass: "sticker-section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "align-center"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/sticker-img.png",
      "alt": "도배, 고민되셨나요? 이제 고민하지 마세요! 매직스티커벽지로 간편하게 셀프도배를 완성하세요."
    }
  })])])]), _c('div', {
    staticClass: "sticker-section2"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('h2', {
    staticClass: "page-title50 align-center"
  }, [_vm._v("디아이디 매직스티커벽지 "), _c('br', {
    staticClass: "pc"
  }), _c('strong', [_vm._v("4개 강점 요소")])]), _c('div', {
    staticClass: "sticker-list"
  }, [_c('ol', [_c('li', [_c('h3', {
    staticClass: "sl-txt"
  }, [_c('span', {
    staticClass: "sl-num"
  }, [_vm._v("1")]), _vm._v(" 기포가 생겨도 여러 번 떼였다 붙이가 가능해서 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("쉽게 제거할 수 있습니다. ")]), _c('div', {
    staticClass: "sl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/sticker-img2.png",
      "alt": "이미지"
    }
  })])]), _c('li', [_c('h3', {
    staticClass: "sl-txt"
  }, [_c('span', {
    staticClass: "sl-num"
  }, [_vm._v("2")]), _vm._v(" Extra-washable 제품으로 표면이 오염되었을 경우 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("물걸레등으로 쉽게 제가 가능합니다. ")]), _c('div', {
    staticClass: "sl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/sticker-img3.png",
      "alt": "이미지"
    }
  })])]), _c('li', [_c('h3', {
    staticClass: "sl-txt"
  }, [_c('span', {
    staticClass: "sl-num"
  }, [_vm._v("3")]), _vm._v(" 시공 후 떼야할 경우 전혀 본드 자국 같은 이물질이 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("남지 않습니다. ")]), _c('div', {
    staticClass: "sl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/sticker-img4.png",
      "alt": "이미지"
    }
  })])]), _c('li', [_c('h3', {
    staticClass: "sl-txt"
  }, [_c('span', {
    staticClass: "sl-num"
  }, [_vm._v("4")]), _vm._v(" 매직스티커 벽지는 실크벽지.합지벽지 등 어떤 기존 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("벽지위에도 완벽하게 부착이 가능합니다. ")]), _c('div', {
    staticClass: "sl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/sticker-img5.png",
      "alt": "이미지"
    }
  })])])])]), _c('div', {
    staticClass: "align-center"
  }, [_c('a', {
    staticClass: "button button--round button--point button--xl",
    attrs: {
      "role": "link",
      "href": "https://smartstore.naver.com/didstore",
      "target": "_blank"
    }
  }, [_vm._v("쇼핑몰 바로가기")])])])]), _c('div', {
    staticClass: "sticker-section3"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('h2', {
    staticClass: "page-title55 align-center"
  }, [_vm._v("질감이 다른 단색부터 수준 높은 "), _c('br', {
    staticClass: "pc"
  }), _c('strong', [_vm._v("패턴 디자인까지 한 눈에!")])]), _c('div', {
    staticClass: "sticker-design-list"
  }, [_c('ul', [_c('li', [_c('div', {
    staticClass: "sdl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/sticker-img6.png",
      "alt": "이미지"
    }
  })]), _c('h2', {
    staticClass: "sdl-tit"
  }, [_vm._v("화이트")])]), _c('li', [_c('div', {
    staticClass: "sdl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/sticker-img7.png",
      "alt": "이미지"
    }
  })]), _c('h2', {
    staticClass: "sdl-tit"
  }, [_vm._v("그레이")])]), _c('li', [_c('div', {
    staticClass: "sdl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/sticker-img8.png",
      "alt": "이미지"
    }
  })]), _c('h2', {
    staticClass: "sdl-tit"
  }, [_vm._v("매트릭브릭")])]), _c('li', [_c('div', {
    staticClass: "sdl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/sticker-img9.png",
      "alt": "이미지"
    }
  })]), _c('h2', {
    staticClass: "sdl-tit"
  }, [_vm._v("라티스그레이")])]), _c('li', [_c('div', {
    staticClass: "sdl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/sticker-img10.png",
      "alt": "이미지"
    }
  })]), _c('h2', {
    staticClass: "sdl-tit"
  }, [_vm._v("엔틱트리")])]), _c('li', [_c('div', {
    staticClass: "sdl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/sticker-img11.png",
      "alt": "이미지"
    }
  })]), _c('h2', {
    staticClass: "sdl-tit"
  }, [_vm._v("블루타일")])]), _c('li', [_c('div', {
    staticClass: "sdl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/sticker-img12.png",
      "alt": "이미지"
    }
  })]), _c('h2', {
    staticClass: "sdl-tit"
  }, [_vm._v("플라워가든")])])])])])])])]), _c('li')])])]);

}]

export { render, staticRenderFns }