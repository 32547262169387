<template>
	<div class="wrap wrap--sub">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
            <div class="collection-index-container">
                <div class="page-head">
                    <div class="page-head__inner">
                        <h1 class="page-head__title">DIY</h1>
                        <!-- <ul class="breadcrumb">
                            <li class="breadcrumb__list">Home</li>
                            <li class="breadcrumb__list">컬렉션</li>
                            <li class="breadcrumb__list">국내</li>
                            <li class="breadcrumb__list">디앤디 컬렉션 인덱스</li>
                        </ul> -->
                    </div>
                </div>
                
                <ul class="tab-list tab-style">
                    <li><a href="/diy/sticker" class="button">매직스티커벽지</a></li>
                    <li class="on"><button type="button" class="button">매직풀나오는 벽지</button></li>
                </ul>

                <ul class="tab-contents">
                    <li>
                    </li>
                    <li class="on">
                        <!-- S: 매직풀나오는벽지 -->
                        <div>
                            <div class="glue-section">
                                <div class="container">
                                    <!-- <h2 class="page-title50 align-center">세계가 인정하는 <br class="pc"/><strong><span class="color-point">디아이디</span> 매직 풀나오는 벽지</strong></h2> -->
                                    <div class="align-center">
                                        <img src="/images/sub/diy/glue-img.png" alt="물에 담갔다가 빼서 붙이면 끝!">
                                    </div>
                                    <div class="glue-icon-text">
                                        <ul>
                                            <li>
                                                <i class="icon icon-like"></i>
                                                <h3 class="git-tit">유해물질 테스트 완료</h3>
                                                <p class="git-txt">포름알데히드 및 실내 유해물질이 검출되지 않은 안전한 제품입니다</p>
                                            </li>
                                            
                                            <li>
                                                <i class="icon icon-leaf"></i>
                                                <h3 class="git-tit">친환경 풀 사용</h3>
                                                <p class="git-txt">인체에 무해한 친환경 풀을 사용하였습니다. 접착력이 우수하고 벽지를 바른 직 후 밀어서 이동 가능하기 때문에 셀프 시공에 용이합니다.</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <!-- <p class="page-txt24 align-center">Sherwin Williams, 홈디포, 월마트 등에서 <br class="pc"/>판매되고 있는 제품을 이제 국내에서도 만나 보실 수 있습니다.</p>
                                    <p class="page-txt24 align-center">쉽고 빠르고 간편한 풀나오는 벽지로 <br class="pc"/>셀프인테리어에 도전해보세요!</p> -->
                                    <h2 class="page-title55 align-center">한눈매직, 풀나오는벽지 <br class="pc"><strong>이제 국내에서도 만나보실 수 있습니다!</strong></h2>
                                    <div class="glue-list">
                                        <ul>
                                            <li>
                                                <div class="gl-wrap">
                                                    <span class="gl-num">1</span>
                                                    <h3 class="gl-tit">풀칠 작업이 필요없어 <br class="pc"/>누구나 쉽게 도배할 수 있습니다.</h3>
                                                    <p class="gl-txt">우표와 같이 특수 제작된 풀이 미리 발라져 있어 <br class="pc"/>물에 담그고 붙이기만 하면 끝!!</p>
                                                    <div class="gl-img-wrap">
                                                        <div class="gl-img"><img src="/images/sub/diy/glue-img2.png" alt="이미지"></div>
                                                        <div class="gl-img"><img src="/images/sub/diy/glue-img3.png" alt="이미지"></div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="gl-wrap">
                                                    <span class="gl-num">2</span>
                                                    <h3 class="gl-tit">포름알데히드 및 총휘발성유기화합물로 부터 <br class="pc"/>안전한 특수한 풀이 발라져있어  굳을 염려가 없습니다. </h3>
                                                    <p class="gl-txt">기존 풀바른 벽지는 1~2일안에 시공하지 않으면 풀이 굳어서 버려야 하지만 <br class="pc"/>매직풀나오는 벽지는 특수 가공 풀이라 장기간 보관이 가능합니다.</p>
                                                    <div class="gl-img-wrap">
                                                        <div class="gl-img"><img src="/images/sub/diy/glue-img4.png" alt="이미지"></div>
                                                        <div class="gl-img"><img src="/images/sub/diy/glue-img5.png" alt="이미지"></div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="gl-wrap">
                                                    <span class="gl-num">3</span>
                                                    <h3 class="gl-tit">폭이 53cm로 diy 리모델링에 제격</h3>
                                                    <p class="gl-txt">일반 풀바른 실크벽지는 106cm로 전문 도배사가아니면 작업하기 매우 어렵지만 <br class="pc"/>매직풀나오는 벽지는 유럽 표준인 53cm 벽지로 혼자 작업하기에 가장 알맞은 <br class="pc"/>사이즈로 제작되었습니다. </p>
                                                    <div class="gl-img-wrap">
                                                        <div class="gl-img"><img src="/images/sub/diy/glue-img6.png" alt="이미지"></div>
                                                        <div class="gl-img"><img src="/images/sub/diy/glue-img7.png" alt="이미지"></div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="gl-wrap">
                                                    <span class="gl-num">4</span>
                                                    <h3 class="gl-tit">세련되고 감각적인  패턴 디자인</h3>
                                                    <p class="gl-txt">기존 제품에 풀만 바르는 벽지가 아닌 diy에 최적화된 패턴으로 <br class="pc"/>보수용, 포인트용 등 diy벽지의 모든 것을 담았습니다.</p>
                                                    <div class="gl-img-wrap">
                                                        <div class="gl-img"><img src="/images/sub/diy/glue-img8.png" alt="이미지"></div>
                                                        <div class="gl-img"><img src="/images/sub/diy/glue-img9.png" alt="이미지"></div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="gl-wrap">
                                                    <span class="gl-num">5</span>
                                                    <h3 class="gl-tit">누구나 쉽게 이음매를 완벽히 맞출 수 있습니다.</h3>
                                                    <p class="gl-txt">풀이 굳기전까지 윤활역할을 하여 벽지를 미세하게 조정할 수 있어 <br class="pc"/>초보자들이 어려워하는 이음매를 쉽게 맞출수 있습니다.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="gl-wrap">
                                                    <span class="gl-num">6</span>
                                                    <h3 class="gl-tit">제거 시 기존 벽지 손상 없이 제거 가능</h3>
                                                    <p class="gl-txt">벽지 제거도 걱정 없이! 실크 벽지 위에 시공한 풀나오는 벽지는 <br class="pc"/>기존 벽지에 손상없이 손쉽게 제거 가능합니다.</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="glue-section2">
                                <div class="container">
                                    <h2 class="page-title50 align-center"><strong>풀나오는벽지 활용하기</strong></h2>
                                    <p class="page-text18 align-center color-grey9">매직 풀나오는 벽지는 53cm폭에 10m길이라서 2롤이면 <br class="pc"/>4m 정도의 벽 한면을 도배하기 적당합니다.</p>
                                    <div class="glue-use-list">
                                        <ul>
                                            <li>
                                                <h3 class="gul-tit">1. 침실에 포인트를 주고 싶을 때</h3>
                                                <div class="gul-img"><img src="/images/sub/diy/glue-img10.png" alt="이미지"></div>
                                            </li>
                                            <li>
                                                <h3 class="gul-tit">2. 오래된 벽지를 바꾸고 싶을 때</h3>
                                                <div class="gul-img"><img src="/images/sub/diy/glue-img11.png" alt="이미지"></div>
                                            </li>
                                            <li>
                                                <h3 class="gul-tit">3. 자투리 공간, 작은방을 특별하게 꾸미고 싶을 때</h3>
                                                <div class="gul-img"><img src="/images/sub/diy/glue-img12.png" alt="이미지"></div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="glue-section3">
                                <div class="container">
                                    <h2 class="page-title55 align-center">한 눈에 배우는 <br class="pc"/><strong>매직 스티커 벽지 시공방법!</strong></h2>
                                    <div class="glue-build-list">
                                        <ol>
                                            <li>
                                                <div class="gbl-wrap">
                                                    <span class="gbl-num">1</span>
                                                    <p class="gbl-txt">준비물을 준비하세요</p>
                                                    <div class="gbl-img"><img src="/images/sub/diy/glue-img13.png" alt="이미지"></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="gbl-wrap">
                                                    <span class="gbl-num">2</span>
                                                    <p class="gbl-txt">시공할 벽면을 <br class="pc"/>깨끗하게 닦아주세요</p>
                                                    <div class="gbl-img"><img src="/images/sub/diy/glue-img14.png" alt="이미지"></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="gbl-wrap">
                                                    <span class="gbl-num">3</span>
                                                    <p class="gbl-txt">시공할 벽의 사이즈를 <br class="pc"/>측정해주세요</p>
                                                    <div class="gbl-img"><img src="/images/sub/diy/glue-img15.png" alt="이미지"></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="gbl-wrap">
                                                    <span class="gbl-num">4</span>
                                                    <p class="gbl-txt">측정 사이즈에 맞춰 <br class="pc"/>제품을 커팅해주세요</p>
                                                    <div class="gbl-img"><img src="/images/sub/diy/glue-img16.png" alt="이미지"></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="gbl-wrap">
                                                    <span class="gbl-num">5</span>
                                                    <p class="gbl-txt">제품을 물에 충분히 <br class="pc"/>담가주세요</p>
                                                    <div class="gbl-img"><img src="/images/sub/diy/glue-img17.png" alt="이미지"></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="gbl-wrap">
                                                    <span class="gbl-num">6</span>
                                                    <p class="gbl-txt">접착면끼리 맞닿도록 <br class="pc"/>접어주세요</p>
                                                    <div class="gbl-img"><img src="/images/sub/diy/glue-img18.png" alt="이미지"></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="gbl-wrap">
                                                    <span class="gbl-num">7</span>
                                                    <p class="gbl-txt">풀이 올라온 벽지를 <br class="pc"/>벽에 부착해주세요</p>
                                                    <div class="gbl-img"><img src="/images/sub/diy/glue-img19.png" alt="이미지"></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="gbl-wrap">
                                                    <span class="gbl-num">8</span>
                                                    <p class="gbl-txt">헤라를 사용해 시옷자 <br class="pc"/>모양으로 움직이며 <br class="pc"/>공기를 빼주면 완성!</p>
                                                    <div class="gbl-img"><img src="/images/sub/diy/glue-img20.png" alt="이미지"></div>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div class="glue-section4">
                                <div class="container">
                                    <div class="align-center">
                                        <a role="link" href="https://smartstore.naver.com/didstore" target="_blank" class="button button--round button--point button--xl">쇼핑몰 바로가기</a>
                                    </div>
                                    <h2 class="page-title40 align-center">일반 실크벽지와 동일한 패브릭패턴부터 <br class="pc"/>인테리어에 포인트를 더해주는 포인트패턴까지 <br class="pc"/><strong>다양한 매직풀나오는 벽지를 만나보세요!</strong></h2>
                                    <div class="glue-design-list">
                                        <ul>
                                            <li>
                                                <div class="gdl-wrap">
                                                    <div class="gdl-con">
                                                        <h3 class="gdl-tit">1.패브릭 <strong>화이트</strong></h3>
                                                        <p class="gdl-txt">PP5100-1</p>
                                                    </div>
                                                    <div class="gdl-img"><img src="/images/sub/diy/glue-img21.png" alt="이미지"></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="gdl-wrap">
                                                    <div class="gdl-con">
                                                        <h3 class="gdl-tit">2.패브릭 <strong>베이지</strong></h3>
                                                        <p class="gdl-txt">PP5100-2</p>
                                                    </div>
                                                    <div class="gdl-img"><img src="/images/sub/diy/glue-img22.png" alt="이미지"></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="gdl-wrap">
                                                    <div class="gdl-con">
                                                        <h3 class="gdl-tit">3.패브릭 <strong>라이트 그레이</strong></h3>
                                                        <p class="gdl-txt">PP5100-3</p>
                                                    </div>
                                                    <div class="gdl-img"><img src="/images/sub/diy/glue-img23.png" alt="이미지"></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="gdl-wrap">
                                                    <div class="gdl-con">
                                                        <h3 class="gdl-tit">4.패브릭 <strong>그레이</strong></h3>
                                                        <p class="gdl-txt">PP5100-4</p>
                                                    </div>
                                                    <div class="gdl-img"><img src="/images/sub/diy/glue-img24.png" alt="이미지"></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="gdl-wrap">
                                                    <div class="gdl-con">
                                                        <h3 class="gdl-tit">5.패브릭 <strong>핑크</strong></h3>
                                                        <p class="gdl-txt">PP5100-5</p>
                                                    </div>
                                                    <div class="gdl-img"><img src="/images/sub/diy/glue-img25.png" alt="이미지"></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="gdl-wrap">
                                                    <div class="gdl-con">
                                                        <h3 class="gdl-tit">6.패브릭 <strong>머스타드옐로우</strong></h3>
                                                        <p class="gdl-txt">PP5100-6</p>
                                                    </div>
                                                    <div class="gdl-img"><img src="/images/sub/diy/glue-img26.png" alt="이미지"></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="gdl-wrap">
                                                    <div class="gdl-con">
                                                        <h3 class="gdl-tit">7.패브릭 <strong>오렌지</strong></h3>
                                                        <p class="gdl-txt">PP5100-7</p>
                                                    </div>
                                                    <div class="gdl-img"><img src="/images/sub/diy/glue-img27.png" alt="이미지"></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="gdl-wrap">
                                                    <div class="gdl-con">
                                                        <h3 class="gdl-tit">8.패브릭 <strong>브라운</strong></h3>
                                                        <p class="gdl-txt">PP5100-8</p>
                                                    </div>
                                                    <div class="gdl-img"><img src="/images/sub/diy/glue-img28.png" alt="이미지"></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="gdl-wrap">
                                                    <div class="gdl-con">
                                                        <h3 class="gdl-tit">9.패브릭 <strong>민트</strong></h3>
                                                        <p class="gdl-txt">PP5100-9</p>
                                                    </div>
                                                    <div class="gdl-img"><img src="/images/sub/diy/glue-img29.png" alt="이미지"></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="gdl-wrap">
                                                    <div class="gdl-con">
                                                        <h3 class="gdl-tit">10.패브릭 <strong>피콕그린</strong></h3>
                                                        <p class="gdl-txt">PP5100-10</p>
                                                    </div>
                                                    <div class="gdl-img"><img src="/images/sub/diy/glue-img30.png" alt="이미지"></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="gdl-wrap">
                                                    <div class="gdl-con">
                                                        <h3 class="gdl-tit">11.패브릭 <strong>블루</strong></h3>
                                                        <p class="gdl-txt">PP5100-11</p>
                                                    </div>
                                                    <div class="gdl-img"><img src="/images/sub/diy/glue-img31.png" alt="이미지"></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="gdl-wrap">
                                                    <div class="gdl-con">
                                                        <h3 class="gdl-tit">12.패브릭 <strong>인디고</strong></h3>
                                                        <p class="gdl-txt">PP5100-12</p>
                                                    </div>
                                                    <div class="gdl-img"><img src="/images/sub/diy/glue-img32.png" alt="이미지"></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="gdl-wrap">
                                                    <div class="gdl-con">
                                                        <h3 class="gdl-tit">13.패브릭 <strong>자수플라워</strong></h3>
                                                        <p class="gdl-txt">PP5101-1</p>
                                                    </div>
                                                    <div class="gdl-img"><img src="/images/sub/diy/glue-img33.png" alt="이미지"></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="gdl-wrap">
                                                    <div class="gdl-con">
                                                        <h3 class="gdl-tit">14.패브릭 <strong>지오그레이</strong></h3>
                                                        <p class="gdl-txt">PP5102-1</p>
                                                    </div>
                                                    <div class="gdl-img"><img src="/images/sub/diy/glue-img34.png" alt="이미지"></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="gdl-wrap">
                                                    <div class="gdl-con">
                                                        <h3 class="gdl-tit">15.패브릭 <strong>오지그레이</strong></h3>
                                                        <p class="gdl-txt">PP5103-1</p>
                                                    </div>
                                                    <div class="gdl-img"><img src="/images/sub/diy/glue-img35.png" alt="이미지"></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="gdl-wrap">
                                                    <div class="gdl-con">
                                                        <h3 class="gdl-tit">16.패브릭 <strong>링크그레이</strong></h3>
                                                        <p class="gdl-txt">PP5104-1</p>
                                                    </div>
                                                    <div class="gdl-img"><img src="/images/sub/diy/glue-img36.png" alt="이미지"></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="gdl-wrap">
                                                    <div class="gdl-con">
                                                        <h3 class="gdl-tit">17.패브릭 <strong>브릭내추럴</strong></h3>
                                                        <p class="gdl-txt">PP5105-1</p>
                                                    </div>
                                                    <div class="gdl-img"><img src="/images/sub/diy/glue-img37.png" alt="이미지"></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="gdl-wrap">
                                                    <div class="gdl-con">
                                                        <h3 class="gdl-tit">18.패브릭 <strong>타일블루</strong></h3>
                                                        <p class="gdl-txt">PP5106-1</p>
                                                    </div>
                                                    <div class="gdl-img"><img src="/images/sub/diy/glue-img38.png" alt="이미지"></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="gdl-wrap">
                                                    <div class="gdl-con">
                                                        <h3 class="gdl-tit">19.패브릭 <strong>미니타일골드</strong></h3>
                                                        <p class="gdl-txt">PP5107-1</p>
                                                    </div>
                                                    <div class="gdl-img"><img src="/images/sub/diy/glue-img39.png" alt="이미지"></div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- E: 매직풀나오는벽지 -->
                    </li>
                </ul>
            </div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import MainHeader from '@/components/client/inc/main-header.vue';
import MainFooter from '@/components/client/inc/main-footer.vue';

export default{
	components: {
		MainHeader,
		MainFooter
	},
	created() {
	},
	mounted() {
		this.init();
	},
		}
</script>