var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--sub"
  }, [_c('main-header'), _vm._m(0), _c('main-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "collection-index-container"
  }, [_c('div', {
    staticClass: "page-head"
  }, [_c('div', {
    staticClass: "page-head__inner"
  }, [_c('h1', {
    staticClass: "page-head__title"
  }, [_vm._v("DIY")])])]), _c('ul', {
    staticClass: "tab-list tab-style"
  }, [_c('li', [_c('a', {
    staticClass: "button",
    attrs: {
      "href": "/diy/sticker"
    }
  }, [_vm._v("매직스티커벽지")])]), _c('li', {
    staticClass: "on"
  }, [_c('button', {
    staticClass: "button",
    attrs: {
      "type": "button"
    }
  }, [_vm._v("매직풀나오는 벽지")])])]), _c('ul', {
    staticClass: "tab-contents"
  }, [_c('li'), _c('li', {
    staticClass: "on"
  }, [_c('div', [_c('div', {
    staticClass: "glue-section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "align-center"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img.png",
      "alt": "물에 담갔다가 빼서 붙이면 끝!"
    }
  })]), _c('div', {
    staticClass: "glue-icon-text"
  }, [_c('ul', [_c('li', [_c('i', {
    staticClass: "icon icon-like"
  }), _c('h3', {
    staticClass: "git-tit"
  }, [_vm._v("유해물질 테스트 완료")]), _c('p', {
    staticClass: "git-txt"
  }, [_vm._v("포름알데히드 및 실내 유해물질이 검출되지 않은 안전한 제품입니다")])]), _c('li', [_c('i', {
    staticClass: "icon icon-leaf"
  }), _c('h3', {
    staticClass: "git-tit"
  }, [_vm._v("친환경 풀 사용")]), _c('p', {
    staticClass: "git-txt"
  }, [_vm._v("인체에 무해한 친환경 풀을 사용하였습니다. 접착력이 우수하고 벽지를 바른 직 후 밀어서 이동 가능하기 때문에 셀프 시공에 용이합니다.")])])])]), _c('h2', {
    staticClass: "page-title55 align-center"
  }, [_vm._v("한눈매직, 풀나오는벽지 "), _c('br', {
    staticClass: "pc"
  }), _c('strong', [_vm._v("이제 국내에서도 만나보실 수 있습니다!")])]), _c('div', {
    staticClass: "glue-list"
  }, [_c('ul', [_c('li', [_c('div', {
    staticClass: "gl-wrap"
  }, [_c('span', {
    staticClass: "gl-num"
  }, [_vm._v("1")]), _c('h3', {
    staticClass: "gl-tit"
  }, [_vm._v("풀칠 작업이 필요없어 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("누구나 쉽게 도배할 수 있습니다.")]), _c('p', {
    staticClass: "gl-txt"
  }, [_vm._v("우표와 같이 특수 제작된 풀이 미리 발라져 있어 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("물에 담그고 붙이기만 하면 끝!!")]), _c('div', {
    staticClass: "gl-img-wrap"
  }, [_c('div', {
    staticClass: "gl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img2.png",
      "alt": "이미지"
    }
  })]), _c('div', {
    staticClass: "gl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img3.png",
      "alt": "이미지"
    }
  })])])])]), _c('li', [_c('div', {
    staticClass: "gl-wrap"
  }, [_c('span', {
    staticClass: "gl-num"
  }, [_vm._v("2")]), _c('h3', {
    staticClass: "gl-tit"
  }, [_vm._v("포름알데히드 및 총휘발성유기화합물로 부터 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("안전한 특수한 풀이 발라져있어 굳을 염려가 없습니다. ")]), _c('p', {
    staticClass: "gl-txt"
  }, [_vm._v("기존 풀바른 벽지는 1~2일안에 시공하지 않으면 풀이 굳어서 버려야 하지만 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("매직풀나오는 벽지는 특수 가공 풀이라 장기간 보관이 가능합니다.")]), _c('div', {
    staticClass: "gl-img-wrap"
  }, [_c('div', {
    staticClass: "gl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img4.png",
      "alt": "이미지"
    }
  })]), _c('div', {
    staticClass: "gl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img5.png",
      "alt": "이미지"
    }
  })])])])]), _c('li', [_c('div', {
    staticClass: "gl-wrap"
  }, [_c('span', {
    staticClass: "gl-num"
  }, [_vm._v("3")]), _c('h3', {
    staticClass: "gl-tit"
  }, [_vm._v("폭이 53cm로 diy 리모델링에 제격")]), _c('p', {
    staticClass: "gl-txt"
  }, [_vm._v("일반 풀바른 실크벽지는 106cm로 전문 도배사가아니면 작업하기 매우 어렵지만 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("매직풀나오는 벽지는 유럽 표준인 53cm 벽지로 혼자 작업하기에 가장 알맞은 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("사이즈로 제작되었습니다. ")]), _c('div', {
    staticClass: "gl-img-wrap"
  }, [_c('div', {
    staticClass: "gl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img6.png",
      "alt": "이미지"
    }
  })]), _c('div', {
    staticClass: "gl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img7.png",
      "alt": "이미지"
    }
  })])])])]), _c('li', [_c('div', {
    staticClass: "gl-wrap"
  }, [_c('span', {
    staticClass: "gl-num"
  }, [_vm._v("4")]), _c('h3', {
    staticClass: "gl-tit"
  }, [_vm._v("세련되고 감각적인 패턴 디자인")]), _c('p', {
    staticClass: "gl-txt"
  }, [_vm._v("기존 제품에 풀만 바르는 벽지가 아닌 diy에 최적화된 패턴으로 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("보수용, 포인트용 등 diy벽지의 모든 것을 담았습니다.")]), _c('div', {
    staticClass: "gl-img-wrap"
  }, [_c('div', {
    staticClass: "gl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img8.png",
      "alt": "이미지"
    }
  })]), _c('div', {
    staticClass: "gl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img9.png",
      "alt": "이미지"
    }
  })])])])]), _c('li', [_c('div', {
    staticClass: "gl-wrap"
  }, [_c('span', {
    staticClass: "gl-num"
  }, [_vm._v("5")]), _c('h3', {
    staticClass: "gl-tit"
  }, [_vm._v("누구나 쉽게 이음매를 완벽히 맞출 수 있습니다.")]), _c('p', {
    staticClass: "gl-txt"
  }, [_vm._v("풀이 굳기전까지 윤활역할을 하여 벽지를 미세하게 조정할 수 있어 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("초보자들이 어려워하는 이음매를 쉽게 맞출수 있습니다.")])])]), _c('li', [_c('div', {
    staticClass: "gl-wrap"
  }, [_c('span', {
    staticClass: "gl-num"
  }, [_vm._v("6")]), _c('h3', {
    staticClass: "gl-tit"
  }, [_vm._v("제거 시 기존 벽지 손상 없이 제거 가능")]), _c('p', {
    staticClass: "gl-txt"
  }, [_vm._v("벽지 제거도 걱정 없이! 실크 벽지 위에 시공한 풀나오는 벽지는 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("기존 벽지에 손상없이 손쉽게 제거 가능합니다.")])])])])])])]), _c('div', {
    staticClass: "glue-section2"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('h2', {
    staticClass: "page-title50 align-center"
  }, [_c('strong', [_vm._v("풀나오는벽지 활용하기")])]), _c('p', {
    staticClass: "page-text18 align-center color-grey9"
  }, [_vm._v("매직 풀나오는 벽지는 53cm폭에 10m길이라서 2롤이면 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("4m 정도의 벽 한면을 도배하기 적당합니다.")]), _c('div', {
    staticClass: "glue-use-list"
  }, [_c('ul', [_c('li', [_c('h3', {
    staticClass: "gul-tit"
  }, [_vm._v("1. 침실에 포인트를 주고 싶을 때")]), _c('div', {
    staticClass: "gul-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img10.png",
      "alt": "이미지"
    }
  })])]), _c('li', [_c('h3', {
    staticClass: "gul-tit"
  }, [_vm._v("2. 오래된 벽지를 바꾸고 싶을 때")]), _c('div', {
    staticClass: "gul-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img11.png",
      "alt": "이미지"
    }
  })])]), _c('li', [_c('h3', {
    staticClass: "gul-tit"
  }, [_vm._v("3. 자투리 공간, 작은방을 특별하게 꾸미고 싶을 때")]), _c('div', {
    staticClass: "gul-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img12.png",
      "alt": "이미지"
    }
  })])])])])])]), _c('div', {
    staticClass: "glue-section3"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('h2', {
    staticClass: "page-title55 align-center"
  }, [_vm._v("한 눈에 배우는 "), _c('br', {
    staticClass: "pc"
  }), _c('strong', [_vm._v("매직 스티커 벽지 시공방법!")])]), _c('div', {
    staticClass: "glue-build-list"
  }, [_c('ol', [_c('li', [_c('div', {
    staticClass: "gbl-wrap"
  }, [_c('span', {
    staticClass: "gbl-num"
  }, [_vm._v("1")]), _c('p', {
    staticClass: "gbl-txt"
  }, [_vm._v("준비물을 준비하세요")]), _c('div', {
    staticClass: "gbl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img13.png",
      "alt": "이미지"
    }
  })])])]), _c('li', [_c('div', {
    staticClass: "gbl-wrap"
  }, [_c('span', {
    staticClass: "gbl-num"
  }, [_vm._v("2")]), _c('p', {
    staticClass: "gbl-txt"
  }, [_vm._v("시공할 벽면을 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("깨끗하게 닦아주세요")]), _c('div', {
    staticClass: "gbl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img14.png",
      "alt": "이미지"
    }
  })])])]), _c('li', [_c('div', {
    staticClass: "gbl-wrap"
  }, [_c('span', {
    staticClass: "gbl-num"
  }, [_vm._v("3")]), _c('p', {
    staticClass: "gbl-txt"
  }, [_vm._v("시공할 벽의 사이즈를 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("측정해주세요")]), _c('div', {
    staticClass: "gbl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img15.png",
      "alt": "이미지"
    }
  })])])]), _c('li', [_c('div', {
    staticClass: "gbl-wrap"
  }, [_c('span', {
    staticClass: "gbl-num"
  }, [_vm._v("4")]), _c('p', {
    staticClass: "gbl-txt"
  }, [_vm._v("측정 사이즈에 맞춰 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("제품을 커팅해주세요")]), _c('div', {
    staticClass: "gbl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img16.png",
      "alt": "이미지"
    }
  })])])]), _c('li', [_c('div', {
    staticClass: "gbl-wrap"
  }, [_c('span', {
    staticClass: "gbl-num"
  }, [_vm._v("5")]), _c('p', {
    staticClass: "gbl-txt"
  }, [_vm._v("제품을 물에 충분히 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("담가주세요")]), _c('div', {
    staticClass: "gbl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img17.png",
      "alt": "이미지"
    }
  })])])]), _c('li', [_c('div', {
    staticClass: "gbl-wrap"
  }, [_c('span', {
    staticClass: "gbl-num"
  }, [_vm._v("6")]), _c('p', {
    staticClass: "gbl-txt"
  }, [_vm._v("접착면끼리 맞닿도록 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("접어주세요")]), _c('div', {
    staticClass: "gbl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img18.png",
      "alt": "이미지"
    }
  })])])]), _c('li', [_c('div', {
    staticClass: "gbl-wrap"
  }, [_c('span', {
    staticClass: "gbl-num"
  }, [_vm._v("7")]), _c('p', {
    staticClass: "gbl-txt"
  }, [_vm._v("풀이 올라온 벽지를 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("벽에 부착해주세요")]), _c('div', {
    staticClass: "gbl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img19.png",
      "alt": "이미지"
    }
  })])])]), _c('li', [_c('div', {
    staticClass: "gbl-wrap"
  }, [_c('span', {
    staticClass: "gbl-num"
  }, [_vm._v("8")]), _c('p', {
    staticClass: "gbl-txt"
  }, [_vm._v("헤라를 사용해 시옷자 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("모양으로 움직이며 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("공기를 빼주면 완성!")]), _c('div', {
    staticClass: "gbl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img20.png",
      "alt": "이미지"
    }
  })])])])])])])]), _c('div', {
    staticClass: "glue-section4"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "align-center"
  }, [_c('a', {
    staticClass: "button button--round button--point button--xl",
    attrs: {
      "role": "link",
      "href": "https://smartstore.naver.com/didstore",
      "target": "_blank"
    }
  }, [_vm._v("쇼핑몰 바로가기")])]), _c('h2', {
    staticClass: "page-title40 align-center"
  }, [_vm._v("일반 실크벽지와 동일한 패브릭패턴부터 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("인테리어에 포인트를 더해주는 포인트패턴까지 "), _c('br', {
    staticClass: "pc"
  }), _c('strong', [_vm._v("다양한 매직풀나오는 벽지를 만나보세요!")])]), _c('div', {
    staticClass: "glue-design-list"
  }, [_c('ul', [_c('li', [_c('div', {
    staticClass: "gdl-wrap"
  }, [_c('div', {
    staticClass: "gdl-con"
  }, [_c('h3', {
    staticClass: "gdl-tit"
  }, [_vm._v("1.패브릭 "), _c('strong', [_vm._v("화이트")])]), _c('p', {
    staticClass: "gdl-txt"
  }, [_vm._v("PP5100-1")])]), _c('div', {
    staticClass: "gdl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img21.png",
      "alt": "이미지"
    }
  })])])]), _c('li', [_c('div', {
    staticClass: "gdl-wrap"
  }, [_c('div', {
    staticClass: "gdl-con"
  }, [_c('h3', {
    staticClass: "gdl-tit"
  }, [_vm._v("2.패브릭 "), _c('strong', [_vm._v("베이지")])]), _c('p', {
    staticClass: "gdl-txt"
  }, [_vm._v("PP5100-2")])]), _c('div', {
    staticClass: "gdl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img22.png",
      "alt": "이미지"
    }
  })])])]), _c('li', [_c('div', {
    staticClass: "gdl-wrap"
  }, [_c('div', {
    staticClass: "gdl-con"
  }, [_c('h3', {
    staticClass: "gdl-tit"
  }, [_vm._v("3.패브릭 "), _c('strong', [_vm._v("라이트 그레이")])]), _c('p', {
    staticClass: "gdl-txt"
  }, [_vm._v("PP5100-3")])]), _c('div', {
    staticClass: "gdl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img23.png",
      "alt": "이미지"
    }
  })])])]), _c('li', [_c('div', {
    staticClass: "gdl-wrap"
  }, [_c('div', {
    staticClass: "gdl-con"
  }, [_c('h3', {
    staticClass: "gdl-tit"
  }, [_vm._v("4.패브릭 "), _c('strong', [_vm._v("그레이")])]), _c('p', {
    staticClass: "gdl-txt"
  }, [_vm._v("PP5100-4")])]), _c('div', {
    staticClass: "gdl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img24.png",
      "alt": "이미지"
    }
  })])])]), _c('li', [_c('div', {
    staticClass: "gdl-wrap"
  }, [_c('div', {
    staticClass: "gdl-con"
  }, [_c('h3', {
    staticClass: "gdl-tit"
  }, [_vm._v("5.패브릭 "), _c('strong', [_vm._v("핑크")])]), _c('p', {
    staticClass: "gdl-txt"
  }, [_vm._v("PP5100-5")])]), _c('div', {
    staticClass: "gdl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img25.png",
      "alt": "이미지"
    }
  })])])]), _c('li', [_c('div', {
    staticClass: "gdl-wrap"
  }, [_c('div', {
    staticClass: "gdl-con"
  }, [_c('h3', {
    staticClass: "gdl-tit"
  }, [_vm._v("6.패브릭 "), _c('strong', [_vm._v("머스타드옐로우")])]), _c('p', {
    staticClass: "gdl-txt"
  }, [_vm._v("PP5100-6")])]), _c('div', {
    staticClass: "gdl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img26.png",
      "alt": "이미지"
    }
  })])])]), _c('li', [_c('div', {
    staticClass: "gdl-wrap"
  }, [_c('div', {
    staticClass: "gdl-con"
  }, [_c('h3', {
    staticClass: "gdl-tit"
  }, [_vm._v("7.패브릭 "), _c('strong', [_vm._v("오렌지")])]), _c('p', {
    staticClass: "gdl-txt"
  }, [_vm._v("PP5100-7")])]), _c('div', {
    staticClass: "gdl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img27.png",
      "alt": "이미지"
    }
  })])])]), _c('li', [_c('div', {
    staticClass: "gdl-wrap"
  }, [_c('div', {
    staticClass: "gdl-con"
  }, [_c('h3', {
    staticClass: "gdl-tit"
  }, [_vm._v("8.패브릭 "), _c('strong', [_vm._v("브라운")])]), _c('p', {
    staticClass: "gdl-txt"
  }, [_vm._v("PP5100-8")])]), _c('div', {
    staticClass: "gdl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img28.png",
      "alt": "이미지"
    }
  })])])]), _c('li', [_c('div', {
    staticClass: "gdl-wrap"
  }, [_c('div', {
    staticClass: "gdl-con"
  }, [_c('h3', {
    staticClass: "gdl-tit"
  }, [_vm._v("9.패브릭 "), _c('strong', [_vm._v("민트")])]), _c('p', {
    staticClass: "gdl-txt"
  }, [_vm._v("PP5100-9")])]), _c('div', {
    staticClass: "gdl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img29.png",
      "alt": "이미지"
    }
  })])])]), _c('li', [_c('div', {
    staticClass: "gdl-wrap"
  }, [_c('div', {
    staticClass: "gdl-con"
  }, [_c('h3', {
    staticClass: "gdl-tit"
  }, [_vm._v("10.패브릭 "), _c('strong', [_vm._v("피콕그린")])]), _c('p', {
    staticClass: "gdl-txt"
  }, [_vm._v("PP5100-10")])]), _c('div', {
    staticClass: "gdl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img30.png",
      "alt": "이미지"
    }
  })])])]), _c('li', [_c('div', {
    staticClass: "gdl-wrap"
  }, [_c('div', {
    staticClass: "gdl-con"
  }, [_c('h3', {
    staticClass: "gdl-tit"
  }, [_vm._v("11.패브릭 "), _c('strong', [_vm._v("블루")])]), _c('p', {
    staticClass: "gdl-txt"
  }, [_vm._v("PP5100-11")])]), _c('div', {
    staticClass: "gdl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img31.png",
      "alt": "이미지"
    }
  })])])]), _c('li', [_c('div', {
    staticClass: "gdl-wrap"
  }, [_c('div', {
    staticClass: "gdl-con"
  }, [_c('h3', {
    staticClass: "gdl-tit"
  }, [_vm._v("12.패브릭 "), _c('strong', [_vm._v("인디고")])]), _c('p', {
    staticClass: "gdl-txt"
  }, [_vm._v("PP5100-12")])]), _c('div', {
    staticClass: "gdl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img32.png",
      "alt": "이미지"
    }
  })])])]), _c('li', [_c('div', {
    staticClass: "gdl-wrap"
  }, [_c('div', {
    staticClass: "gdl-con"
  }, [_c('h3', {
    staticClass: "gdl-tit"
  }, [_vm._v("13.패브릭 "), _c('strong', [_vm._v("자수플라워")])]), _c('p', {
    staticClass: "gdl-txt"
  }, [_vm._v("PP5101-1")])]), _c('div', {
    staticClass: "gdl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img33.png",
      "alt": "이미지"
    }
  })])])]), _c('li', [_c('div', {
    staticClass: "gdl-wrap"
  }, [_c('div', {
    staticClass: "gdl-con"
  }, [_c('h3', {
    staticClass: "gdl-tit"
  }, [_vm._v("14.패브릭 "), _c('strong', [_vm._v("지오그레이")])]), _c('p', {
    staticClass: "gdl-txt"
  }, [_vm._v("PP5102-1")])]), _c('div', {
    staticClass: "gdl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img34.png",
      "alt": "이미지"
    }
  })])])]), _c('li', [_c('div', {
    staticClass: "gdl-wrap"
  }, [_c('div', {
    staticClass: "gdl-con"
  }, [_c('h3', {
    staticClass: "gdl-tit"
  }, [_vm._v("15.패브릭 "), _c('strong', [_vm._v("오지그레이")])]), _c('p', {
    staticClass: "gdl-txt"
  }, [_vm._v("PP5103-1")])]), _c('div', {
    staticClass: "gdl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img35.png",
      "alt": "이미지"
    }
  })])])]), _c('li', [_c('div', {
    staticClass: "gdl-wrap"
  }, [_c('div', {
    staticClass: "gdl-con"
  }, [_c('h3', {
    staticClass: "gdl-tit"
  }, [_vm._v("16.패브릭 "), _c('strong', [_vm._v("링크그레이")])]), _c('p', {
    staticClass: "gdl-txt"
  }, [_vm._v("PP5104-1")])]), _c('div', {
    staticClass: "gdl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img36.png",
      "alt": "이미지"
    }
  })])])]), _c('li', [_c('div', {
    staticClass: "gdl-wrap"
  }, [_c('div', {
    staticClass: "gdl-con"
  }, [_c('h3', {
    staticClass: "gdl-tit"
  }, [_vm._v("17.패브릭 "), _c('strong', [_vm._v("브릭내추럴")])]), _c('p', {
    staticClass: "gdl-txt"
  }, [_vm._v("PP5105-1")])]), _c('div', {
    staticClass: "gdl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img37.png",
      "alt": "이미지"
    }
  })])])]), _c('li', [_c('div', {
    staticClass: "gdl-wrap"
  }, [_c('div', {
    staticClass: "gdl-con"
  }, [_c('h3', {
    staticClass: "gdl-tit"
  }, [_vm._v("18.패브릭 "), _c('strong', [_vm._v("타일블루")])]), _c('p', {
    staticClass: "gdl-txt"
  }, [_vm._v("PP5106-1")])]), _c('div', {
    staticClass: "gdl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img38.png",
      "alt": "이미지"
    }
  })])])]), _c('li', [_c('div', {
    staticClass: "gdl-wrap"
  }, [_c('div', {
    staticClass: "gdl-con"
  }, [_c('h3', {
    staticClass: "gdl-tit"
  }, [_vm._v("19.패브릭 "), _c('strong', [_vm._v("미니타일골드")])]), _c('p', {
    staticClass: "gdl-txt"
  }, [_vm._v("PP5107-1")])]), _c('div', {
    staticClass: "gdl-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/diy/glue-img39.png",
      "alt": "이미지"
    }
  })])])])])])])])])])])])]);

}]

export { render, staticRenderFns }